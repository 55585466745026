// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"

// Utils
import { loadAssets, animateSprites } from "../../utils/canvas"

// Components
import SEO from "../../components/seo"
import { drawImages } from "../../components/illustration/images"
import PageComponents from "../../components/pageComponents"

// Style
import sharedStyles from "./../pages.module.scss"

// Assets
import bgImg from "./assets/bg.jpg"
import mgImg from "./assets/mg.png"
import fgImg from "./assets/fg.png"
import textImg from "./assets/text.png"
import scannerImg from "./assets/scanner.png"
import hoverPadsImg from "./assets/hover-pads.png"
import hoverLinesImg from "./assets/hover-lines.png"
import manImg from "./assets/man.png"
import squirrelImg from "./assets/squirrel.png"
import squirrelArmImg from "./assets/squirrel-arm.png"
import bushMaskImg from "./assets/bush-mask.png"

// Sprites
import droneImg from "./assets/sprites/drone.png"
import sdgImg from "./assets/sprites/sdg.png"
import graphTopImg from "./assets/sprites/graph-top.png"
import graphBottomImg from "./assets/sprites/graph-bottom.png"
import fireworksLeftImg from "./assets/sprites/fireworks-left.png"
import fireworksRightImg from "./assets/sprites/fireworks-right.png"

// Audio
import ambient from "./assets/audio/ambient.mp3"
import vo from "./assets/audio/vo.mp3"

const pageData = {
  index: 0,
  preHeading: "Direct Democracy",
  heading:
    "This must be the place, not where power rests, but where it listens.",
  content:
    "This is where our collective will collects. Here is where we choose a different kind of growth and it is here that we come to hold account. This is where we measure progress not by riches but by lives enriched. Here, we are citizens of Scotland, and of Stonehaven, Stornoway and Stranraer, and we decide as both. This is where we come to lend our knowledge, our experience and our compassion to the nation. Where service is given and authority is shared.",
  slug: "direct-democracy",
  nextSlug: "powerful-communities",
}

const DirectDemocracy = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  // Asset setup
  const bg = useRef({
      parallax: 0.2,
      src: bgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    mg = useRef({
      parallax: 0.5,
      src: mgImg,
      el: null,
      offset: { x: -80, y: 860 },
    }),
    fg = useRef({
      parallax: 1,
      src: fgImg,
      el: null,
      offset: { x: -80, y: 560 },
    }),
    sdg = useRef({
      parallax: 0.2,
      src: sdgImg,
      el: null,
      offset: { x: 652, y: 826 },
      size: { w: 550, h: 550 },
      currentSprite: 1,
      spriteFrames: 7,
      slowFactor: 16,
      loop: true,
      paused: false,
    }),
    graph1 = useRef({
      parallax: 0.2,
      src: graphTopImg,
      el: null,
      offset: { x: 2835, y: 854 },
      size: { w: 560, h: 180 },
      currentSprite: 1,
      spriteFrames: 7,
      slowFactor: 6,
      loop: false,
      paused: true,
    }),
    graph2 = useRef({
      parallax: 0.2,
      src: graphBottomImg,
      el: null,
      offset: { x: 2835, y: 1315 },
      size: { w: 560, h: 180 },
      currentSprite: 1,
      spriteFrames: 7,
      slowFactor: 6,
      loop: false,
      paused: true,
    }),
    text = useRef({
      parallax: 0.2,
      src: textImg,
      el: null,
      offset: { x: 1980, y: 504 },
    }),
    scanner = useRef({
      parallax: 0.25,
      src: scannerImg,
      el: null,
      offset: { x: 1715, y: 496, r: 0 },
      rotationPoint: { x: 454, y: -100 },
    }),
    drone = useRef({
      parallax: 0.25,
      src: droneImg,
      el: null,
      offset: { x: 2100, y: 723, r: 0 },
      size: { w: 180, h: 220 },
      rotationPoint: { x: 90, y: -348 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    fireworks1 = useRef({
      parallax: 1,
      src: fireworksLeftImg,
      el: null,
      offset: { x: 1760, y: 130 },
      size: { w: 280, h: 470 },
      currentSprite: 1,
      spriteFrames: 20,
      slowFactor: 3,
      loop: true,
    }),
    fireworks2 = useRef({
      parallax: 1,
      src: fireworksRightImg,
      el: null,
      offset: { x: 2390, y: 130 },
      size: { w: 280, h: 470 },
      currentSprite: 6,
      spriteFrames: 20,
      slowFactor: 3,
      loop: true,
    }),
    hoverPads = useRef({
      parallax: 0.5,
      src: hoverPadsImg,
      el: null,
      offset: { x: 755, y: 1716 },
    }),
    hoverLines = useRef({
      parallax: 0.5,
      src: hoverLinesImg,
      el: null,
      offset: { x: 723, y: 1758 },
    }),
    man = useRef({
      parallax: 0.5,
      src: manImg,
      el: null,
      offset: { x: 655, y: 1100 },
    }),
    squirrel = useRef({
      parallax: 1,
      src: squirrelImg,
      el: null,
      offset: { x: 130, y: 1814 },
    }),
    squirrelArm = useRef({
      parallax: 1,
      src: squirrelArmImg,
      el: null,
      offset: { x: 300, y: 1970 },
      hidden: true,
    }),
    bushMask = useRef({
      parallax: 1,
      src: bushMaskImg,
      el: null,
      offset: { x: 0, y: 1753 },
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    bg.current,
    scanner.current,
    text.current,
    drone.current,
    fireworks1.current,
    fireworks2.current,
    sdg.current,
    graph1.current,
    graph2.current,
    mg.current,
    hoverPads.current,
    hoverLines.current,
    man.current,
    fg.current,
    squirrel.current,
    bushMask.current,
    squirrelArm.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([
    drone.current,
    sdg.current,
    graph1.current,
    graph2.current,
    fireworks1.current,
    fireworks2.current,
  ])

  // Set the mouse entry point if there is one passed in
  const mousePoint = {
    x: entry.state.mousePos ? entry.state.mousePos.x : 0,
    y: entry.state.mousePos ? entry.state.mousePos.y : 0,
  }

  // The point to focus on when the page enters
  const focusPoint = {
    x: 2957,
    y: 1394,
  }

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    scanner.current.hidden = false

    if (frameCount % 6 === 0) {
      scanner.current.hidden = true
    }

    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  let squirrelArmTimeout = null

  const showSquirrel = direction => {
    if (direction === "in") {
      squirrelArmTimeout = setTimeout(() => {
        squirrelArm.current.hidden = false
      }, 200)
      gsap.to(squirrel.current.offset, {
        y: 1714,
        duration: 0.3,
        ease: "power3.inOut",
      })
      gsap.to(squirrelArm.current.offset, {
        y: 1870,
        duration: 0.3,
        ease: "power3.inOut",
      })
    } else {
      if (squirrelArmTimeout) clearTimeout(squirrelArmTimeout)
      squirrelArm.current.hidden = true
      gsap.to(squirrel.current.offset, {
        y: 1814,
        duration: 0.3,
        ease: "power3.inOut",
      })
      gsap.to(squirrelArm.current.offset, {
        y: 1970,
        duration: 0.3,
        ease: "power3.inOut",
      })
    }
  }

  let hoverChairYAnimation = null,
    hoverPadsYAnim = null,
    hoverLinesYAnim = null

  const hoverChair = direction => {
    if (hoverChairYAnimation) hoverChairYAnimation.kill()
    if (hoverPadsYAnim) hoverPadsYAnim.kill()
    if (hoverLinesYAnim) hoverLinesYAnim.kill()
    if (direction === "in") {
      hoverChairYAnimation = gsap.to(man.current.offset, {
        y: 1000,
        duration: 1,
        ease: "power3.inOut",
        onComplete: () => {
          hoverChairYAnimation = gsap.fromTo(
            man.current.offset,
            { y: 1000 },
            {
              y: 1030,
              duration: 0.8,
              ease: "power1.inOut",
              repeat: -1,
              yoyo: true,
            }
          )
        },
      })
      hoverPadsYAnim = gsap.to(hoverPads.current.offset, {
        y: 1616,
        duration: 1,
        ease: "power3.inOut",
        onComplete: () => {
          hoverPadsYAnim = gsap.fromTo(
            hoverPads.current.offset,
            { y: 1616 },
            {
              y: 1646,
              duration: 0.8,
              ease: "power1.inOut",
              repeat: -1,
              yoyo: true,
            }
          )
        },
      })
      hoverLinesYAnim = gsap.to(hoverLines.current.offset, {
        y: 1658,
        duration: 1,
        ease: "power3.inOut",
        onComplete: () => {
          hoverLinesYAnim = gsap.fromTo(
            hoverLines.current.offset,
            { y: 1658 },
            {
              y: 1688,
              duration: 0.8,
              ease: "power2.inOut",
              repeat: -1,
              yoyo: true,
            }
          )
        },
      })
    } else {
      if (hoverChairYAnimation) hoverChairYAnimation.kill()
      if (hoverPadsYAnim) hoverPadsYAnim.kill()
      if (hoverLinesYAnim) hoverLinesYAnim.kill()
      hoverChairYAnimation = gsap.to(man.current.offset, {
        y: 1100,
        duration: 1.5,
        ease: "power3.out",
      })
      hoverPadsYAnim = gsap.to(hoverPads.current.offset, {
        y: 1716,
        duration: 1.5,
        ease: "power3.out",
      })
      hoverLinesYAnim = gsap.to(hoverLines.current.offset, {
        y: 1758,
        duration: 1.5,
        ease: "power3.out",
      })
    }
  }

  // Initial animations
  const startingAnimations = () => {
    gsap.fromTo(
      hoverLines.current.offset,
      { x: 723 },
      { x: 733, duration: 0.2, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      scanner.current.offset,
      { r: 10 },
      { r: -10, duration: 4, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      drone.current.offset,
      { r: 10 },
      { r: -10, duration: 4, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      text.current.offset,
      { x: 1985 },
      { x: 1975, duration: 4, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
  }

  const showGraph1 = () => {
    graph1.current.currentSprite = 1
    graph1.current.paused = false
  }

  const showGraph2 = () => {
    graph2.current.currentSprite = 1
    graph2.current.paused = false
  }

  // Animate in
  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
      startingAnimations()
    }, 1000)
    let showGraph1Interval = setInterval(showGraph1, 6000)
    let showGraph2Interval = setInterval(showGraph2, 6000)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
      if (squirrelArmTimeout) clearTimeout(squirrelArmTimeout)
      if (showGraph1Interval) clearTimeout(showGraph1Interval)
      if (showGraph2Interval) clearTimeout(showGraph2Interval)
      if (hoverChairYAnimation) hoverChairYAnimation.kill()
      if (hoverPadsYAnim) hoverPadsYAnim.kill()
      if (hoverLinesYAnim) hoverLinesYAnim.kill()
    }
  }, [])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div>
      <SEO title={pageData.heading} />
      <div className={sharedStyles.page}>
        <PageComponents
          index={pageData.index}
          preHeading={pageData.preHeading}
          heading={pageData.heading}
          content={pageData.content}
          mousePoint={mousePoint}
          focusPoint={focusPoint}
          playing={reveal}
          draw={draw}
          fromPage={pageData.slug}
          to={`/${pageData.nextSlug}`}
          ambient={ambient}
          vo={vo}
          interactiveElements={[
            {
              event: showSquirrel,
              x: 0,
              y: 1550,
              w: 580,
              h: 600,
            },
            {
              event: hoverChair,
              x: 680,
              y: 960,
              w: 590,
              h: 760,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default DirectDemocracy
